<template>
    <div class="order-list">
	    <normal-table
            ref="normalTable"
            method="post"
            :tableAction="tableAction"
            :url="url"
            :extra-query="{}"
            hasResetBtn
            :searchForm.sync="form"
            :page-size="200"
            :has-search-form="true"
            @select="select"
            @select-all="selectAll"
            @loaded="loaded"
        >
            <template slot="formItem">
	            <el-form-item label="资金类型">
		            <el-select v-model="form.coinType" placeholder="资金类型" style="width:70px">
			            <el-option label="金币" value="coin"></el-option>
		            </el-select>
	            </el-form-item>
	            <el-form-item label="金额变动">
		            <el-select v-model="form.changeType" placeholder="金额变动" style="width:70px">
			            <el-option label="增加" value="add"></el-option>
			            <el-option label="减少" value="reduce"></el-option>
		            </el-select>
	            </el-form-item>
	            
	            <el-form-item label="操作类型">
		            <el-select v-model="form.reasonType" placeholder="操作类型" style="width:70px">
			            <el-option label="充值" value="charge"></el-option>
			            <el-option label='卡密兑换' value="activeCode"></el-option>
			            <el-option label='图片生成' value="generatePic"></el-option>
			            <el-option label='文字生成' value="generateWords"></el-option>
			            <el-option label='生成卡密' value="generateActivationCode"></el-option>
		            </el-select>
	            </el-form-item>
	            <el-form-item class="search-item" label="金额">
		            <el-input
			            v-model="form.amountMin"
			            type="number"
			            placeholder="金额"
			            class="input-amount"
		            ></el-input> -
		            <el-input
			            v-model="form.amountMax"
			            type="number"
			            class="input-amount"
			            placeholder="金额"
		            ></el-input>
	            </el-form-item>
	            <el-form-item label="余额查询模式">
		            <el-select v-model="form.balanceMode" placeholder="否" style="width:70px">
			            <el-option label="是" value="1"></el-option>
			            <el-option label="否" value="0"></el-option>
		            </el-select>
	            </el-form-item>
	            <el-form-item class="search-item" label="时间">
		            <el-date-picker
			            v-model="dateRange"
			            type="dates"
			            range-separator="至"
			            value-format="yyyy-MM-dd"
			            @change="dateChange"
			            placeholder="取两天之间的时间"
			            style="width: 200px;padding: 0 10px;"
		            >
		            </el-date-picker>
	            </el-form-item>
            </template>
            <div slot="otherContent" class="otherContent">
                <div class="tableAction">
                </div>
            </div>
        </normal-table>
    </div>
</template>

<script>
import NormalTable from "@/components/NormalTable/index.vue";
import {getCookie} from "@/utils/auth";

export default {
    data() {
        return {
            total: 0,
            otherMap: {totalAccount:0},
            dateRange: [],
            form: {
                status: ''
            },
            url: "/client/wallet/list",
            tableAction: {
                label: "操作",
                prop: "handle",
                align: "center",
                formatter: ({_id, status}) => {
                    return (
                        <div class="action">
	                        ----
                        </div>
                    );
                },
            },
            selectList: [],
	        
	        payType: 'wechatPay',
	        chargeAmount: '',
	        chargeAmountCoin:"",
	        chargeQrCode: '',
	        picturePrice: 0,
	        articlePrice: 0,
	        coinPrice: 10,
        };
    },

    components: {
        NormalTable,
	    VueQr: () => import('vue-qr')
    },

    computed: {},

    mounted() {
	    this.$http.post('/client/common/getPublicConfig', {}).then(res => {
		    if (res.code === 0) {
			    res.data.list.forEach(item => {
				    if (item.key === 'picturePrice') {
					    this.picturePrice = item.value
				    } else if (item.key === 'articlePrice') {
					    this.articlePrice = item.value
				    } else if (item.key === 'coinPrice') {
					    this.coinPrice = item.value
				    }
			    })
		    }
	    })
    },

    watch: {
	    chargeAmount(val) {
		    this.changeAmount()
	    },
    },

    methods: {
	    changeAmount() {
		    //只能输入数字或者小数
		    this.chargeAmount = this.chargeAmount.replace(/[^\d.]/g, '')
				this.chargeAmountCoin = this.chargeAmount * this.coinPrice
	    },
	    dateChange() {
		    if(this.dateRange) {
			    this.form.createdAt = this.dateRange.join(',')
		    }
	    },
	    charge() {
				if (!this.chargeAmount) {
					this.$message({
						type: "error",
						message: "请输入充值金额",
					});
					return
				}
		    this.$http.post("/client/wallet/createChargeOrder",
			    {payAmount: this.chargeAmount, payType: this.payType, orderType:'charge'}
		    ).then((res) => {
			    if (res.code === 0) {
				    this.chargeQrCode = res.data.qrCodeUrl
				    this.$message({
					    type: "success",
					    message: "创建订单成功",
				    });
			    } else {
				    this.$message({
					    type: "error",
					    message: res.msg,
				    });
			    }
		    });
				
	    },
        // 获取接口数据
        loaded(data) {
            // console.log(data)
        },
        select(selection) {
            this.selectList = selection;
            console.log(selection);
        },
        selectAll(selection) {
            this.selectList = selection;
        },
	    
        resetForm() {
            this.dateRange = []
            this.form = {}
        },
        setOtherMap(map) {
            this.otherMap = map
        },
	      preview(_id) {
		      this.$router.push({ path: "/generate/preview", query: { _id } }).then(() => {
			      try {
				      let matchedRoute = this.$route.matched.find(route => route.path === '/generate/preview');
				      matchedRoute.instances.default.initArticle()
			      } catch (e) {
				      console.log(e)
			      }
		      });
	      }
    },
};
</script>
<style lang="scss" scoped>
.el-form-item {
    flex-wrap: nowrap;
}
.order-list {
    .total {
        font-size: 14px;
        color: #333;
        text-align: right;
        padding-right: 30px;
    }
    .input-amount{
        display: inline-block;
        width: 100px;
    }
    .phone {
        width: 115px;
    }
    .day {
        width: 60px;
    }
}
.otherContent {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    flex-wrap: wrap;
    .tableAction {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-right: 5px;
        .el-button {
            margin-bottom: 5px;
        }
    }
    .allAccount {
        display: grid;
        place-items: end;
    }
}
::v-deep .el-message-box {
    max-width: 100% !important;
}
.el-message-box__message {
    max-width: 100% !important;
}
.action {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	.item {
		margin-left: 5px !important;
		margin-right: 5px !important;
	}
}
.charge-container {
	max-width: 500px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 1rem 0 2rem 0;
	border: solid 1px #ebeef5;
	margin: 2rem auto 1rem auto;
	border-radius: 1rem;
	.price-container {
		text-align: center;
		line-height: 2rem;
	}
	.balance {
		font-size: 1.5rem;
		font-weight: bold;
		display: flex;
		margin-bottom: 1rem;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.charge-input {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-top:1rem;
		.charge-button {
			margin-top: .5rem;
			width: 5rem;
		}
	}
	.charge-type {
		display: flex;
		justify-content: space-around;
		.type-box {
			width: 3rem;
			text-align: center;
			cursor: pointer;
			padding: 0.3rem 0;
			box-sizing: border-box;
			border: solid 1px rgba(0, 0, 0, 0);
		}
		.active {
			border: solid 1px #409eff;
			border-radius: 5px;
		}
		img {
			width: 2rem;
		}
	}
}
</style>